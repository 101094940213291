<template>
  <div id="app">
    <main class="container">
      <div class="main">
        <MyHeader @showParentPopup="showParentPopup" />
        <van-popup
          class="right-popup"
          v-model="showPopup"
          position="right"
          get-container="#app"
          :style="{
            width: '2rem',
            height: '100%',
            background: '#000000',
            padding: '.15rem .23rem 0',
          }"
        >
          <img src="@/assets/images/up.png" alt="up" class="up" />
          <div class="lib" @click="handleCLickHome">
            <span>卖单列表</span>
          </div>
          <div class="lib" @click="handleCLickBuyList">
            <span>买单列表</span>
          </div>
          <div class="lib" @click="handleCLickPanel">
            <span>我的卖单</span>
          </div>
          <div class="lib" @click="handleCLickMyBuy">
            <span>我的买单</span>
          </div>
		 <div class="lib" @click="handleCLickExchange">
		    <span>HFH兑换</span>
		  </div>
		  <div class="lib" @click="handleCLickWithdrawal">
		    <span>SOL转出</span>
		  </div>
          <img src="@/assets/images/down.png" alt="down" class="down" />

          <img
            src="@/assets/images/close.png"
            alt="close"
            class="close-icon"
            @click="handleClickPopup"
          />
          <img
            src="@/assets/images/logo.png"
            alt="logo"
            class="footer-logo"
          />
        </van-popup>
        <section>
          <div class="router">
            <div>
              <router-view />
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader.vue";
import WalletState from "./state/WalletState";
import { saveRef } from "./utils/utils";
export default {
  name: "App",
  components: {
    MyHeader,
  },
  data() {
    return {
      showPopup: false,
    };
  },
  mounted() {
    saveRef();
    WalletState.connetWallet();
  },
  methods: {
    showParentPopup(value) {
      this.showPopup = value;
    },
    handleClickPopup() {
      this.showPopup = false;
    },
    handleCLickHome() {
      this.$router.push("/");
      this.showPopup = this.showPopup ? false : null;
    },
    handleCLickBuyList() {
      this.$router.push("/buyList");
      this.showPopup = this.showPopup ? false : null;
    },
    handleCLickPanel() {
      this.$router.push("/me");
      this.showPopup = this.showPopup ? false : null;
    },
    handleCLickMyBuy() {
      this.$router.push("/myBuy");
      this.showPopup = this.showPopup ? false : null;
    },
	handleCLickExchange() {
	  this.$router.push("/exchange");
	  this.showPopup = this.showPopup ? false : null;
	},
	handleCLickWithdrawal(){
		this.$router.push("/withdrawal");
		this.showPopup = this.showPopup ? false : null;
	},
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .container {
    width: 100%;
    max-width: 450px;
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    color: #fff;
    .main {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      margin: 0 auto;
      position: relative;
      z-index: 3;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
.right-popup {
  .close-icon {
    position: absolute;
    top: 0.25rem;
    right: 0.16rem;
    cursor: pointer;
    height: 0.35rem;
  }
  .footer-logo {
    position: absolute;
    bottom: 0.25rem;
    right: 0.16rem;
    cursor: pointer;
    height: 0.6rem;
	display: block;
	border-radius: 50%;
  }
  .up {
    margin-top: 0.8rem;
    width: 92%;
    margin-bottom: 0.2rem;
  }
  .down {
    width: 92%;
  }
  .lib {
    width: 100%;
    height: 0.32rem;
    line-height: 0.3rem;
    font-size: 0.17rem;
    color: #70001c;
    margin-bottom: 0.2rem;
    cursor: pointer;
    background: #ffa100;
    border-radius: 0.08rem;
    text-align: center;
    font-weight: 800;
    span {
      display: inline-block;
      flex: 1;
      text-align: right;
    }
  }
}
</style>