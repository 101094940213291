import Web3 from 'web3'
import axios from 'axios';
import {
	showFromWei,
	showAccount,
	showLongAccount,
	showLongLongAccount,
	toWei,
	getRef,
} from "../utils/utils";
class WalletState {
	wallet = {
		chainId: null,
		account: null,
		lang: "Ch"
	}

	config = {
		Market: "0x34C620De73Cc084C39a8cA282B6ca3c66A7f8C0B",
		url: "https://hfh.chaobi.vip",
	}

	listeners = []

	constructor() {
		this.subcripeWeb3();
		this.getConfig();
	}
	//listen the wallet event
	async subcripeWeb3() {
		let page = this;
		if (window.ethereum) {
			window.ethereum.on('accountsChanged', function (accounts) {
				page.connetWallet();
				console.log('accountsChanged', accounts);
			});
			window.ethereum.on('chainChanged', function (chainId) {
				page.connetWallet();
				page.getConfig();
				console.log('chainChanged', chainId);
			});
		}
		// window.ethereum.on('connect', (connectInfo) => { });
		// window.ethereum.on('disconnect', (err) => { });
		// window.ethereum.isConnected();

		//         4001
		// The request was rejected by the user
		// -32602
		// The parameters were invalid
		// -32603
		// Internal error
	}

	async getConfig() {
		if (!Web3.givenProvider) {
			console.log("not wallet found");
		}

		var storage = window.localStorage;
		if (storage) {
			var lang = storage["language"];
			if (lang) {
				this.wallet.lang = lang;
			}
		}
		console.log("config", this.config);
		console.log("wallet", this.wallet);
		this.notifyAll();
	}

	async connetWallet() {
		let provider = Web3.givenProvider || window.ethereum;
		console.log("provider", provider);
		if (provider) {
			Web3.givenProvider = provider;
			const web3 = new Web3(provider);
			const chainId = await web3.eth.getChainId();
			console.log(chainId);
			this.wallet.chainId = chainId;
			const accounts = await web3.eth.requestAccounts();
			console.log('accounts');
			console.log(accounts);
			this.wallet.account = accounts[0];
			localStorage.setItem('address',this.wallet.account);
			let invitor = getRef();
			
			if (this.wallet.account) {
				const data = {
					address: this.wallet.account,
					yao: invitor
				};
				
				axios.post(this.config.url + '/index/login/api_login', data)
					.then(response => {
						// 请求成功，处理响应数据  
						var items = response.data;
						if (items.code == 1) {
							localStorage.setItem(this.wallet.account,items.token);
							
								//this.sqWallet();
						}
			
					})
					.catch(error => {
						// 请求失败，处理错误  
						console.error('请求失败:', error);
					});
			}
			this.notifyAll();
		} else {
			setTimeout(() => {
				this.connetWallet();
			}, 3000);
		}
	}
	async sqWallet() {
			let provider = Web3.givenProvider || window.ethereum;
			if (provider) {
				Web3.givenProvider = provider;
				const web3 = new Web3(provider);
				const chainId = await web3.eth.getChainId();
				this.wallet.chainId = chainId;
				const accounts = await web3.eth.requestAccounts();
				this.wallet.account = accounts[0];
				
				if (this.wallet.account) {
					var token = localStorage.getItem(this.wallet.account);//如果不存在 token 直接授权
					if(!token){
						try{
							const signaturePromise = web3.eth.personal.sign(this.wallet.message,
							this.wallet.account,'');
							console.log(123)
							signaturePromise.then(signatureResult => {  
							    const signatureString = `${signatureResult}`;  
							this.wallet.signatureString=signatureString;
							if(signatureString){//如果签名成功
								const data = {
								   	address: this.wallet.account,
									signedMessage: signatureString,
									message: this.wallet.message
								   };
								   axios.post(this.config.url + '/index/login/sign', data)
								   	.then(response => {
								   		// 请求成功，处理响应数据  
								   		var items = response.data;
								   		if (items.data == 1) {
								   			//检查缓存数据
								   			localStorage.setItem(this.wallet.account,items.token);
								   		    this.wallet.token=items.token;
											location.reload();
											this.notifyAll();
								   		}else{
											console.log('签名失败');
										}
								   
								   	}).catch(error => {
								   		// 请求失败，处理错误  
								   		console.error('请求失败:', error);
								   	});
							}
							}).catch(error => {  
							    // 处理错误  
							    
							});  
							
						}catch(e){
						
						}
						
					}else{//存在 token  验证token对不对
						 const data = {
						 	address: this.wallet.account,
							token:localStorage.getItem(this.wallet.account)
						 };
						 axios.post(this.config.url + '/index/login/login', data)
						 	.then(response => {
						 		// 请求成功，处理响应数据  
						 		var items = response.data;
								console.log(124)
						 		if (items.data == 1) {
						 			//检查缓存数据
						 			localStorage.setItem(this.wallet.account,items.token);
									this.wallet.token=items.token;
									console.log(this.wallet.account,items.token)
									this.notifyAll();
						 		}else{
									localStorage.setItem(this.wallet.account,'');
									this.wallet.token='';
									this.sqWallet()
								}
						 
						 	}).catch(error => {
						 		// 请求失败，处理错误  
						 		console.error('请求失败:', error);
						 	});
					}
					
				}
			} else {
				setTimeout(() => {
					this.connetWallet();
				}, 3000);
			}
		}
	

	onStateChanged(cb) {
		this.listeners.push(cb);
		cb();
	}

	removeListener(cb) {
		this.listeners = this.listeners.filter(item => item !== cb);
	}

	notifyAll() {
		for (let i = 0; i < this.listeners.length; i++) {
			const cb = this.listeners[i];
			cb();
		}
	}

}
// export const CHAIN_ID = 97
// export const CHAIN_SYMBOL = "tBNB"
// export const CHAIN_ERROR_TIP = "请连接币安测试链钱包使用"
export const CHAIN_ID = 56
export const CHAIN_SYMBOL = "BNB"
export const CHAIN_ERROR_TIP = "请连接币安链钱包使用"
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_INT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
export default new WalletState();
