import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Button, Icon, Row, Col, Tab, Tabs,Popup,Toast,Notify,DropdownMenu, DropdownItem,Dialog,Field,NoticeBar} from "vant"

import("@/assets/style/reset.less")
import 'vant/lib/index.css';

Vue.use(Button).use(Icon).use(Row).use(Col).use(Tab).use(Tabs).use(Popup).use(Toast).use(Notify).use(DropdownMenu).use(DropdownItem).use(Dialog).use(Field).use(NoticeBar)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
